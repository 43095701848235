<template>
  <div class="container">
    <div class="col-sm"></div>
    <div class="col-sm">
      <div class="top-row">
        <div align="center">
          <h1 class="faq-txt avoid-wrap">About Me</h1>
        </div>
      </div>
      <!-- C++ C# Java Spring HTML CSS Javascript PHP Laravel VueJS SQL COBOL JCL  -->

      <div class="row">
        
      </div>
    </div>
    <div class="col-sm"></div>
  </div>
</template>

<script>
</script>

<style scoped>
.faq-txt {
  color: #ffaa00;
  font-size: clamp(4em, 20vw, 8em);
}
</style>